<script setup lang="ts">
import { useAuthStore } from '~~/stores/auth'
import { useHomeStore } from '~~/stores/home'
import { useUserStore } from '~~/stores/user'

defineOptions({
  name: 'HomeNew'
})

const authStore: Record<string, any> = useAuthStore()
const userStore: Record<string, any> = useUserStore()
const homeStore: Record<string, any> = useHomeStore()

const isLoggedIn = computed(() => authStore.state?.isLoggedIn)
const facilitators = computed(() => homeStore.state?.facilitators)
const isProcessingState = computed(() => userStore.state.isProcessingState)
const featuredCourse = computed(() => homeStore.state?.featuredCourse)
const latestCourse = computed(() => homeStore.state?.latestCourse)
const favoritedCourse = computed(() => homeStore.state?.favoritedCourse)

const showOnboarding = ref(isLoggedIn.value && isProcessingState.value)

onMounted(() => {
  homeStore.fetchFacilitators()
  homeStore.fetchFeaturedCourse()
  homeStore.fetchCourses({
    type: 'latestCourse',
    size: 6,
    page: 1
  })
  homeStore.fetchCourses({
    type: 'favoritedCourse'
  })
})
</script>

<template>
  <main id="kg-content" class="kg-content">
    <!--  -->
    <LazyModulesHomeMoleculesSectionHero />
    <LazyModulesHomeMoleculesSectionPartner />
    <LazyModulesHomeMoleculesSectionBanner />

    <LazyModulesHomeMoleculesSectionCourseContinue
      v-if="isLoggedIn"
      type-ref="kursusContinue"
      title="Lanjutkan Belajarmu"
      is-see-more
      class="my-10"
    />

    <LazyModulesHomeMoleculesSectionCourseLoading
      v-if="featuredCourse?.isLoading"
      title="Kursus Unggulan"
      class="my-10"
    />
    <LazyModulesHomeMoleculesSectionCourse
      v-else
      type-ref="kursusUnggulan"
      title="Kursus Unggulan"
      :data="featuredCourse?.data ?? []"
      :is-loading="featuredCourse?.isLoading ?? false"
      class="my-10"
    />
    <LazyModulesHomeMoleculesSectionCourseLoading
      v-if="latestCourse?.isLoading"
      title="Kursus Terbaru"
      class="my-10"
    />
    <LazyModulesHomeMoleculesSectionCourse
      v-else
      type-ref="kursusTerbaru"
      title="Kursus Terbaru"
      :data="latestCourse?.data ?? []"
      :is-loading="latestCourse?.isLoading ?? false"
      is-see-more
      with-arrow
      class="my-10"
    />

    <LazyModulesHomeMoleculesSectionCategories />

    <LazyModulesHomeMoleculesDiscovery />

    <LazyModulesHomeMoleculesSectionMentor
      :data="facilitators?.data ?? []"
      :is-loading="facilitators?.isLoading"
    />

    <LazyModulesHomeMoleculesSectionCourseLoading
      v-if="favoritedCourse?.isLoading"
      title="Kursus Terfavorit"
      class="my-10"
    />
    <LazyModulesHomeMoleculesSectionCourse
      v-else
      type-ref="kursusTerfavorit"
      title="Kursus Terfavorit"
      :data="favoritedCourse?.data ?? []"
      :is-loading="favoritedCourse?.isLoading ?? false"
      is-see-more
      with-arrow
      class="my-10"
    />

    <OrganismsOnboarding v-if="showOnboarding" @skip="showOnboarding = false" />
  </main>
</template>

<style lang="scss">
@use './styles.scss';
</style>
