<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    currentStep?: number
  }>(),
  {
    currentStep: 0
  }
)

const emits = defineEmits(['skip'])
const { currentStep } = toRefs(props)
</script>

<template>
  <div class="onboarding-wrapper">
    <div class="onboarding-container">
      <AtomsOnboardingHead :current-step="currentStep" @skip="emits('skip')" />
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
