<script setup lang="ts">
import { useCategoryStore } from '~~/stores/category'

defineOptions({
  name: 'SectionCategories'
})
const categoryStore = useCategoryStore()
const categories = computed(() => categoryStore.state.categories)

categoryStore.fetchCategories()
</script>

<template>
  <section id="categories">
    <div class="container">
      <a-row :gutter="[24, 24]">
        <a-col :span="24">
          <h2>Jelajahi Kategori</h2>
        </a-col>
        <a-col v-if="categories?.isLoading" :span="24">
          <ul class="categories-list">
            <li v-for="index in 10" :key="`loading-${index}`">
              <LazyModulesHomeAtomsCardCategory :loading="true" />
            </li>
          </ul>
        </a-col>
        <a-col
          v-else-if="!categories?.isLoading && categories?.data?.length"
          :span="24"
        >
          <ul class="categories-list">
            <li v-for="category in categories?.data" :key="category?.serial">
              <LazyModulesHomeAtomsCardCategory :data="category" />
            </li>
          </ul>
        </a-col>
        <a-col v-else :span="24">
          <AtomsEmpty height="100px" description="Kategori tidak ditemukan" />
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
