<script setup lang="ts"></script>

<template>
  <footer class="kg-footer">
    <div class="kg-footer__container container">
      <a-row :gutter="[24, 24]">
        <a-col :xs="24" :sm="24" :md="9">
          <img
            src="~/assets/images/kognisi-logo-white.svg"
            loading="lazy"
            alt="logo-footer"
          />
          <div class="mt-2">
            Kognisi.id adalah bagian dari Growth Center Kompas Gramedia yang
            memfasilitasi pertumbuhan organisasi dan individu lewat beragam
            pilihan kursus online terbaik. Kognisi.id menyediakan kursus online
            di Indonesia yang menawarkan sertifikasi dengan beragam kategori
            kursus yang dapat dipelajari sesuai dengan jadwal Anda.
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="5">
          <div class="kg-footer__label">Kognisi.id</div>

          <ul class="kg-footer__menus">
            <li>
              <a href="https://kognisi.id/" target="_blank">
                Tentang Kognisi.id
              </a>
            </li>
            <li>
              <a href="https://blog.kognisi.id/" target="_blank">Blog</a>
            </li>
          </ul>
        </a-col>

        <a-col :xs="24" :sm="24" :md="5">
          <div class="kg-footer__label">Bantuan</div>

          <ul class="kg-footer__menus">
            <li>
              <nuxt-link to="/pusat-bantuan">Pusat Bantuan</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/ketentuan-layanan">
                Syarat dan Ketentuan
              </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/kebijakan-privasi"> Kebijakan Privasi </nuxt-link>
            </li>
          </ul>
        </a-col>

        <a-col :xs="24" :sm="24" :md="4">
          <div class="kg-footer__label">Ikuti Kami</div>

          <ul class="kg-footer__menus social">
            <li>
              <a
                href="https://www.instagram.com/kognisikg?utm_source=footer&utm_medium=footer&utm_campaign=footer"
                target="_blank"
                rel="nofollow"
              >
                <img
                  src="~/assets/images/footer/instagram.svg"
                  alt="instagram"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@kognisi.id?utm_source=footer&utm_medium=footer&utm_campaign=footer"
                target="_blank"
                rel="nofollow"
              >
                <img src="~/assets/images/footer/tiktok.svg" alt="tiktok" />
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/@KognisiKG??utm_source=footer&utm_medium=footer&utm_campaign=footer"
                target="_blank"
                rel="nofollow"
              >
                <img src="~/assets/images/footer/youtube.svg" alt="youtube" />
              </a>
            </li>
          </ul>
        </a-col>
      </a-row>
    </div>
  </footer>
</template>

<style lang="scss">
@use './styles.scss';
</style>
