<script setup lang="ts">
import { type FacilitatorsDataType } from '~~/stores/home/types'

interface PropTypes {
  data?: FacilitatorsDataType
  isLoading?: boolean
}
const props = withDefaults(defineProps<PropTypes>(), {
  data: null,
  isLoading: false
})
const { data, isLoading } = toRefs(props)
</script>

<template>
  <div class="kg-card-profile">
    <div v-if="isLoading" class="kg-card-profile__image px-2 pt-2 mb-3">
      <AtomsSkeleton height="inherit" />
    </div>
    <div v-else class="kg-card-profile__image">
      <nuxt-img
        :src="data.imageURL"
        alt="mentor-image"
        loading="lazy"
        size="100% sm:164px md:214px"
        format="webp"
        quality="70"
        preload
      />
    </div>
    <div class="kg-card-profile__info">
      <h3 class="info-name">
        <AtomsSkeleton v-if="isLoading" height="21px" />
        <template v-else>
          {{ data?.user?.fullName || '-' }}
        </template>
      </h3>
      <p class="info-role">
        <AtomsSkeleton v-if="isLoading" height="21px" />
        <template v-else>
          {{ data.role }}
        </template>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
