<script setup lang="ts">
/* eslint-disable no-undefined */
const props = withDefaults(
  defineProps<{
    id?: string
    title?: string
    url?: string
    isSeeMore?: boolean
  }>(),
  {
    id: undefined,
    title: 'Default title',
    url: '#',
    isSeeMore: false
  }
)
const { title, url, isSeeMore } = toRefs(props)
</script>

<template>
  <div class="section-header">
    <h2 class="header-title">
      {{ title }}
    </h2>

    <NuxtLink v-if="isSeeMore" :to="url" class="header-more">
      Lihat Semua
      <right-outlined class="ml-3" />
    </NuxtLink>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
