<script setup lang="ts">
import { useUserStore } from '~~/stores/user'

const { isMobile } = useDevice()
const props = withDefaults(
  defineProps<{
    id?: string
    title?: string
    url?: string
    isSeeMore?: boolean
    withArrow?: boolean
    typeRef?: string
    data?: Record<string, any>[] | null
    isLoading?: boolean
    type?: string
  }>(),
  {
    // eslint-disable-next-line no-undefined
    id: undefined,
    title: 'Default title',
    url: '#',
    isSeeMore: false,
    withArrow: false,
    typeRef: 'kursusSection',
    data: () => [],
    isLoading: false,
    type: null
  }
)
const { title, isSeeMore, typeRef, data } = toRefs(props)

const userStore = useUserStore()
const userDetail = computed(() => userStore.state.userDetail)
const courses = ref(data?.value)
const activeIndex = ref(null)

const emits = defineEmits(['showOnboarding'])

const { maxLeft, maxRight, swipeLeft, swipeRight } = useScrollTo()
const {
  triggerReview,
  showReview,
  onCancelReview,
  triggerFinishRatingCourse,
  triggerFinishRatingFacilitator,
  submitAddReview,
  visibleAddRating
} = useGenerateCertificate()

const activeCourseIndex = ({ index, course }: Record<string, any>) => {
  activeIndex.value = index
  triggerReview(course)
}

const finisihAddRating = () => {
  triggerFinishRatingCourse()
  courses.value[activeIndex.value].isReviewed = true
}
</script>

<template>
  <section :id="id" class="section-course">
    <div class="container">
      <ModulesHomeAtomsCourseHeader
        :title="title"
        url="/kategori"
        :is-see-more="isSeeMore"
      />

      <ul :id="typeRef" class="section-course__list">
        <!-- <AtomsArrowNavigation
          v-if="withArrow"
          :id="`prev-${typeRef}`"
          :disabled="!maxLeft"
          type="prev"
          @click="swipeLeft(typeRef, -1146, 500)"
        /> -->
        <li
          v-for="(course, index) in courses"
          :key="course.urlSlug"
          class="section-course__item"
        >
          <ModulesHomeMoleculesCardCourse
            v-bind="course"
            @on-review="activeCourseIndex({ index, course })"
          />
        </li>
        <!-- <AtomsArrowNavigation
          v-if="withArrow"
          :id="`next-${typeRef}`"
          :disabled="!maxRight"
          @click="swipeRight(typeRef, 1146, 500)"
        /> -->
      </ul>

      <div
        v-if="
          type === 'suggestion' &&
          userDetail?.data?.onBoardingState === 'PENDING'
        "
        class="section-course__suggestion-empty"
      >
        <div class="suggestion-empty__img">
          <nuxt-img
            :src="
              isMobile
                ? '/images/course/empty-suggest-mobile.png'
                : '/images/course/empty-suggest-desktop.png'
            "
            alt="empty-suggest"
            loading="lazy"
          />
        </div>
        <div class="ml-6">
          <p class="mb-4">
            Jawab formulir singkat untuk dapatkan rekomendasi kursus paling
            sesuai
          </p>
          <a-button type="primary" @click="emits('showOnboarding')">
            Isi Sekarang
          </a-button>
        </div>
      </div>

      <ClientOnly>
        <ModulesStudiSayaAtomsModalReviewInfo
          :show-review="showReview"
          @submit="submitAddReview"
          @cancel="onCancelReview"
        />
        <AtomsModal
          :visible="visibleAddRating.course"
          :width="1073"
          centered
          :closable="false"
        >
          <LazyModulesKursusCertificateAtomsRating
            title="Tulis Review"
            @on-finish="finisihAddRating"
          />
        </AtomsModal>

        <AtomsModal
          :visible="visibleAddRating.facilitator"
          :width="676"
          centered
          @cancel="triggerFinishRatingFacilitator"
        >
          <LazyModulesKursusCertificateAtomsRatingFacilitator
            @on-finish="triggerFinishRatingFacilitator"
          />
        </AtomsModal>
      </ClientOnly>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
