<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    width?: number
    closable?: boolean
    centered?: boolean
    visible?: boolean
  }>(),
  {
    width: 520,
    closable: true,
    centered: false
  }
)
const emit = defineEmits(['cancel'])
const { width, closable } = toRefs(props)
</script>

<template>
  <a-modal
    :visible="visible"
    class="kg"
    :centered="centered"
    :width="width"
    :footer="null"
    :closable="closable"
    @cancel="emit('cancel')"
  >
    <slot />
  </a-modal>
</template>

<style lang="scss">
@use './styles.scss';
</style>
