<script setup lang="ts">
import { type StudiSayaType } from '~/stores/user/types'

interface PropTypes {
  course?: StudiSayaType
}
const props = withDefaults(defineProps<PropTypes>(), {
  course: () => null
})

const { course } = toRefs(props)
</script>

<template>
  <div class="kg-card-course">
    <NuxtLink :to="`/kursus/${course.urlSlug}`" :replace="true">
      <div class="kg-card-course__image">
        <nuxt-img
          v-if="course?.thumbnailURL"
          :src="course?.thumbnailURL"
          alt="sample course alt"
          loading="lazy"
          size="sm:100%"
          preload
          format="webp"
          quality="80"
        />
      </div>

      <h3 class="kg-card-course__title">
        {{ course.title }}
      </h3>

      <div class="kg-card-course__progress">
        <AtomsProgress :from="course.progressPercentage" :to="100" />
      </div>
    </NuxtLink>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
