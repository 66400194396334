<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    formData?: any | null
    showBack?: boolean
  }>(),
  {
    formData: null,
    showBack: false
  }
)
const { formData } = toRefs(props)

const emits = defineEmits(['onChangeForm', 'onFinish', 'onBack'])

const onOkForm = () => {
  emits('onFinish')
}
</script>

<template>
  <AtomsTheForm
    :form-data="formData"
    module="onboarding-content"
    @finish="onOkForm"
    @on-change-form="emits('onChangeForm')"
  >
    <template #action-button>
      <div class="onboarding-action">
        <a-form-item>
          <a-button block type="primary" size="large" html-type="submit">
            Selanjutnya
          </a-button>
          <a-button
            v-if="showBack"
            block
            size="large"
            class="mt-4"
            @click="emits('onBack')"
            >Kembali</a-button
          >
        </a-form-item>
      </div>
    </template>
  </AtomsTheForm>
</template>

<style lang="scss">
@use './styles.scss';
</style>
