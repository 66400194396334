<script setup lang="ts">
interface IProps {
  visible: boolean
  type: 'phonenumber' | 'email'
  recipient: string
}

const forgotPwSentModalProps = defineProps<IProps>()
const { visible, type, recipient } = toRefs(forgotPwSentModalProps)

const emit = defineEmits(['cancel'])
</script>

<template>
  <a-modal
    class="kg"
    :visible="visible"
    :footer="null"
    :width="450"
    @cancel="emit('cancel')"
  >
    <div class="kg-forgot-password-sent">
      <div class="illustration-container" align="center">
        <img
          v-if="type === 'email'"
          src="~/assets/images/ilustrasi-email-sent.svg"
          alt="email-sent"
          loading="lazy"
        />
        <img
          v-else
          src="~/assets/images/ilustrasi-sms-sent.png"
          alt="sms-sent"
          loading="lazy"
        />
      </div>

      <div class="kg-forgot-password-sent__header">
        <div class="header-title">
          <template v-if="type === 'email'"> Email berhasil dikirim! </template>
          <template v-else> Pesan berhasil dikirim! </template>
        </div>
        <div class="header-subtitle">
          <template v-if="type === 'email'">
            Kami telah mengirim email ke
            <br /><span>{{ recipient }}</span
            >. <br />Silakan periksa kotak masuk atau spam kamu.
          </template>
          <template v-else>
            Kami telah mengirim sms ke
            <br /><span>{{ recipient }}</span> <br />Silakan periksa kotak pesan
            kamu.
          </template>
        </div>
      </div>

      <a-button type="primary" size="large" block @click="emit('cancel')">
        Oke
      </a-button>
    </div>
  </a-modal>
</template>

<style lang="scss">
@use './styles.scss';
</style>
