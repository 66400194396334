import { defineStore } from 'pinia'

import { useMessageStore } from '../message'
import { type CategoryState } from 'types/category'

export const useCategoryStore = defineStore('category', () => {
  const messageStore = useMessageStore()
  const { API_URL } = useBaseUrl()

  const state = reactive<CategoryState>({
    categories: {
      data: [],
      isLoading: false
    }
  })

  const fetchCategories = async () => {
    state.categories.isLoading = true

    try {
      const res: any = await $fetch(`${API_URL}/categories?size=100`)

      const categories = []

      res?.data?.forEach((category: any) => {
        if (
          categories?.length < 10 &&
          !['Creative Industry', 'Tourism', 'Certification']?.includes(
            category?.name
          )
        ) {
          categories.push(category)
        }
      })

      state.categories = {
        data: categories?.map((category: any) => ({
          ...category,
          icon: `${category?.name?.toLowerCase()?.split(' ')?.join('-')}.png`
        })),
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
    } finally {
      state.categories.isLoading = false
    }
  }

  return {
    state,
    fetchCategories
  }
})
