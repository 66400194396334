<script setup lang="ts">
// import { useCategoryStore } from '~~/stores/category'
import { useAuthStore } from '~~/stores/auth'
import { useUserStore } from '~~/stores/user'

type acoountMenuType = {
  key: string
  label: string
  path: string
}

type PropTypes = {
  showSidebar?: boolean
}
const props = withDefaults(defineProps<PropTypes>(), { showSidebar: false })

const { showSidebar } = toRefs(props)
const emit = defineEmits(['closeSidebar', 'showSignin'])

const authStore = useAuthStore()
const isLoggedIn = computed(() => authStore.state.isLoggedIn)

// const categoryStore = useCategoryStore()
// const categories = computed(() => categoryStore?.state.categories)

const userStore = useUserStore()
const userDetail = computed(() => userStore.state.userDetail)

const accountMenu = ref<acoountMenuType[]>([
  {
    key: 'profil',
    label: 'Profil Saya',
    path: '/user'
  },
  {
    key: 'studi-saya',
    label: 'Studi Saya',
    path: '/user/studi-saya'
  },
  {
    key: 'wishlist',
    label: 'Wishlist',
    path: '/user/wishlist'
  },
  {
    key: 'riwayat-transaksi',
    label: 'Riwayat Transaksi',
    path: '/user/riwayat-transaksi'
  }
])
</script>

<template>
  <aside id="sidebar" class="sidebar" :class="showSidebar ? 'show' : 'hide'">
    <ul class="sidebar-list">
      <li class="sidebar-list__close">
        <div class="text-2xl cursor-pointer" @click="emit('closeSidebar')">
          <close-outlined />
        </div>
      </li>
      <li v-if="!isLoggedIn">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-button size="large" block @click="$emit('showSignin')">
              Masuk
            </a-button>
          </a-col>
          <a-col :span="12">
            <a-button
              type="primary"
              size="large"
              block
              @click="$router.push('/signup')"
              >Daftar</a-button
            >
          </a-col>
        </a-row>
      </li>
    </ul>
    <a-menu mode="inline" class="sidebar-menu">
      <template v-if="isLoggedIn">
        <a-menu-item class="sidebar-menu__account" disabled>
          <a-space>
            <a-avatar>
              <template #icon><UserOutlined /></template>
            </a-avatar>

            <div>
              <div class="account-info__name">
                {{ userDetail?.data?.fullName ?? '-' }}
              </div>
              <div class="account-info__email">
                {{ userDetail?.data?.email ?? '-' }}
              </div>
            </div>
          </a-space>
        </a-menu-item>
        <a-menu-item
          v-for="(account, index) in accountMenu"
          :key="account.key"
          class="sidebar-menu__logged"
          :class="index + 1 === accountMenu.length ? 'last' : null"
        >
          <template #icon>
            <UserOutlined v-if="account.key === 'profil'" />
            <ReadOutlined v-else-if="account.key === 'studi-saya'" />
            <HeartOutlined v-else-if="account.key === 'wishlist'" />
            <TransactionOutlined
              v-else-if="account.key === 'riwayat-transaksi'"
            />
            <BellOutlined v-else-if="account.key === 'notifikasi'" />
          </template>
          <span>
            <NuxtLink :to="account.path"> {{ account.label }} </NuxtLink>
          </span>
        </a-menu-item>
      </template>

      <!-- <div :key="categories.isLoading">
        <a-menu-item v-if="categories.isLoading">
          <AtomsSkeleton height="41px" />
        </a-menu-item>
        <a-sub-menu v-else key="kategori">
          <template #icon><appstore-outlined /></template>
          <template #title>Kategori</template>
          <a-menu-item
            v-for="(category, index) in categories?.data"
            :key="index"
          >
            <a :href="`/kategori?search=${category.serial}`">
              {{ category.name }}
            </a>
          </a-menu-item>
        </a-sub-menu>
      </div> -->
      <!-- <a-menu-item key="prakerja">
        <template #icon><laptop-outlined /></template>
        <span>
          <a href="/"> Prakerja </a>
        </span>
      </a-menu-item> -->
      <a-menu-item key="6">
        <template #icon><bulb-outlined /></template>
        <span>
          <a href="https://discovery.kognisi.id/" target="_blank">
            Kognisi.id Discovery
          </a>
        </span>
      </a-menu-item>

      <a-menu-item v-if="isLoggedIn" key="logout" class="sidebar-logout">
        <template #icon><logout-outlined /></template>
        <span>
          <a @click="authStore.logout()"> Keluar </a>
        </span>
      </a-menu-item>
    </a-menu>
  </aside>
</template>

<style lang="scss">
@use './styles.scss';
</style>
