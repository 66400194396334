<script setup lang="ts">
import { type FormInstance } from 'ant-design-vue'
import { Form } from 'ant-design-vue'

import { type DataLoginType } from '~~/stores/auth/types'
import { useAuthStore } from '~~/stores/auth'
import { useUserStore } from '~~/stores/user'
import { useCoursesStore } from '~~/stores/courses'

const route = useRoute()
const useForm = Form.useForm
const showForgotPasswordModal = ref(false)

const formRef = ref<FormInstance>()
const formState = reactive<DataLoginType>({
  email: '',
  password: ''
})
const isRemember = ref<boolean>(false)

const rulesRef = reactive({
  email: [
    {
      required: true,
      message: 'Email harus diisi!'
    },
    { type: 'email', message: 'Format email tidak valid!' }
  ],
  password: [
    {
      required: true,
      message: 'Password harus diisi!'
    }
  ]
})
const errorList = ref({
  email: null,
  password: null
})
const { validate } = useForm(formState, rulesRef)

const openForgotPasswordModal = () => {
  showForgotPasswordModal.value = true
}
const closeForgotPasswordModal = () => {
  showForgotPasswordModal.value = false
  formRef.value.resetFields()
  formState.email = ''
  formState.password = ''
}

const emit = defineEmits(['success'])

const userStore: Record<string, any> = useUserStore()
const fetchUserDetail = () => {
  userStore.getUserDetail({
    resolve: () => {
      emit('success')
    }
  })
}

const authStore: Record<string, any> = useAuthStore()
const coursesStore: Record<string, any> = useCoursesStore()
const handleFinish = (values: DataLoginType) => {
  validate()
    .then(() => {
      authStore.login({
        payload: values,
        resolve: (res: any) => {
          closeForgotPasswordModal()
          fetchUserDetail()
          if (route?.name === 'kursus-slug') {
            coursesStore.fetchCourseDetail({
              payload: route.params?.slug
            })
          }
        }
      })
    })
    .catch(({ errorFields }) => {
      errorFields?.forEach((error: any) => {
        errorList.value[error?.name] = error?.errors[0]
      })
    })
}

const handleSSOGoogle = ({ credential: token }) => {
  authStore.loginSSO({
    payload: { token, provider: 'Google' },
    resolve: () => fetchUserDetail()
  })
}
</script>

<template>
  <div class="kg-signin-form">
    <div class="signin-title">Masuk</div>
    <div class="signin-subtitle">
      Belum punya akun? <NuxtLink to="/signup">Daftar</NuxtLink>
    </div>

    <a-form
      ref="formRef"
      name="signin"
      layout="vertical"
      class="signin-form"
      autocomplete="off"
      :model="formState"
      @finish="handleFinish"
    >
      <a-form-item
        label="Email"
        name="email"
        :validate-status="errorList?.email ? 'error' : null"
      >
        <a-input
          v-model:value="formState.email"
          size="large"
          @change="errorList.email = null"
        />
        <template v-if="errorList?.email" #help>
          {{ errorList?.email }}
        </template>
      </a-form-item>
      <a-form-item
        label="Kata Sandi"
        name="password"
        :validate-status="errorList?.password ? 'error' : null"
      >
        <a-input-password
          v-model:value="formState.password"
          size="large"
          @change="errorList.password = null"
        />
        <template v-if="errorList?.email" #help>
          {{ errorList?.password }}
        </template>
      </a-form-item>
      <a-form-item>
        <div class="flex justify-between">
          <a-checkbox v-model:checked="isRemember">Ingat saya</a-checkbox>
          <a class="signin-form__forgot" @click="openForgotPasswordModal">
            Lupa Kata Sandi?
          </a>
        </div>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" size="large" block html-type="submit">
          Masuk
        </a-button>
      </a-form-item>
    </a-form>

    <div class="signin__exception">
      <div class="exception-line" />
      <span class="exception-text">atau</span>
    </div>
    <a-row class="signin__social" type="flex" :gutter="[10, 16]">
      <a-col flex="1">
        <AtomsButtonSSOGoogle @login="handleSSOGoogle" />
      </a-col>
    </a-row>
  </div>

  <MoleculesForgotPasswordModal
    :visible="showForgotPasswordModal"
    @cancel="closeForgotPasswordModal"
  />
</template>

<style lang="scss">
@use './styles.scss';
</style>
