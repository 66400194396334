<script setup lang="ts">
interface PropTypes {
  from?: number
  to?: number
}
const props = withDefaults(defineProps<PropTypes>(), { from: 5, to: 12 })
const { from, to } = toRefs(props)
const widthProgress = computed(() => (+from.value / +to.value) * 100)
</script>

<template>
  <div class="progress">
    <div class="w-full block relative">
      <div class="progress-line" />
      <div
        class="progress-active"
        :style="{ width: `${widthProgress > 100 ? 100 : widthProgress}%` }"
      />
    </div>
    <div class="progress-counter">
      <span>{{ from }}</span>
      /
      <span>{{ to }}</span>
    </div>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
