<script setup lang="ts">
import useForms from './useForms'

const props = withDefaults(
  defineProps<{
    formData?: any
    module?: string
  }>(),
  {
    formData: null,
    // eslint-disable-next-line no-undefined
    module: undefined
  }
)
const { formData } = toRefs(props)

const emits = defineEmits(['onChangeForm', 'finish', 'validate', 'submit'])

const { formRef, formState } = useForms(formData)

const onChange = ({ form, value }) => {
  if (form.component === 'input' && form?.type === 'number') {
    formState.value[form?.key] = value?.replace(/\D/g, '')
  }
}

watch(
  () => formState.value,
  (newVal) => {
    emits('onChangeForm', toRaw(newVal))
  },
  {
    deep: true
  }
)

const filterOption = (input, option) => {
  return option.label.toLowerCase().includes(input.toLowerCase())
}
</script>

<template>
  <a-form
    :id="formData.id"
    ref="formRef"
    :name="formData.name"
    :model="formState"
    layout="vertical"
    autocomplete="off"
    class="kg-form"
    @validate="emits('validate', $event)"
    @finish="emits('finish', $event)"
    @submit="emits('submit')"
  >
    <a-form-item :class="!isEmpty(module) ? module : null">
      <template v-for="form in formData.jsonForm" :key="form.key">
        <a-form-item
          v-if="form.component === 'input' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <template v-if="form.showExtra" #extra>
            {{ formState[form.key]?.length ?? 0 }} / {{ form.maxLength }}
          </template>
          <a-input
            v-model:value="formState[form.key]"
            :size="form.size"
            :placeholder="form.placeholder"
            :maxlength="form.maxLength"
            :minlength="form.minLength"
            :disabled="form.disabled"
            :suffix="form?.suffix ?? undefined"
            class="input-form"
            @change="onChange({ form, value: $event.target.value })"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'textarea' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <template v-if="form.showExtra" #extra>
            {{ formState[form.key]?.length ?? 0 }} / {{ form.maxLength }}
          </template>
          <a-textarea
            v-model:value="formState[form.key]"
            :placeholder="form.placeholder"
            :rows="form?.row ?? 6"
            :maxlength="form.maxLength"
            :minlength="form.minLength"
            :disabled="form.disabled"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'select' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <template v-if="form.showExtra" #extra>
            {{ formState[form.key]?.length ?? 0 }} / {{ form.maxLength }}
          </template>
          <a-select
            ref="select"
            v-model:value="formState[form.key]"
            :options="form.options"
            :size="form.size"
            :placeholder="form.placeholder"
            :show-search="form.showSearch"
            :disabled="form.disabled"
            :filter-option="filterOption"
          />
        </a-form-item>

        <a-form-item
          v-else-if="form.component === 'select-level' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-select
            ref="select-tags"
            v-model:value="formState[form.key]"
            :options="form.options"
            :size="form.size"
            :field-names="{
              label: 'label',
              value: 'value',
              options: 'children'
            }"
            :placeholder="form.placeholder"
            :disabled="form.disabled"
            :filter-option="filterOption"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'select-multiple-tag' && !form?.hidden"
          :id="form.key"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-select
            v-model:value="formState[form.key]"
            popup-class-name="kg-select-multiple"
            :mode="form?.mode ?? 'tags'"
            :size="form.size"
            :options="form.options"
            :field-names="{
              label: 'label',
              value: 'value',
              options: 'children'
            }"
            :placeholder="form.placeholder"
            :show-search="form.showSearch"
            :disabled="form.disabled"
            :loading="form?.loading ?? false"
            :filter-option="filterOption"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'switch' && !form?.hidden"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-space>
            <a-switch v-model:checked="formState[form.key]" />

            <div>
              <div class="ant-form-item-label">
                <label for="switch">
                  {{ form.label }}
                </label>
              </div>
              <div class="text-sm text-gray-8">
                {{ form.placeholder }}
              </div>
            </div>
          </a-space>
        </a-form-item>

        <a-form-item
          v-else-if="form.component === 'checkbox-group' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-checkbox-group
            v-model:value="formState[form.key]"
            :name="form.key"
            :options="form.options"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'timePicker' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-time-picker
            v-model:value="formState[form.key]"
            :format="form.format"
            :value-format="form.format"
            :size="form.size"
          />
        </a-form-item>
        <a-form-item
          v-else-if="form.component === 'datePicker' && !form?.hidden"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-date-picker
            v-model:value="formState[form.key]"
            :format="form.format"
            :value-format="form.format"
            :size="form.size"
          />
        </a-form-item>
        <a-form-item
          v-if="form.component === 'number'"
          :label="form.label"
          :name="form.key"
          :rules="formData.rulesRef[form.key]"
        >
          <a-input-number
            v-model:value="formState[form.key]"
            :size="form.size"
            :placeholder="form.placeholder"
            :max="form.max"
            :min="form.min"
            class="input-form"
            :formatter="form.formatter"
            :parser="form.parser"
            :step="form.step"
            :decimal-separator="form.decimalSeparator"
            :disabled="form.disabled"
            :controls="form?.controls ?? false"
            :string-mode="form?.type === 'decimal' ?? false"
          >
            <template v-if="form?.addonBefore" #addonBefore>
              <slot name="addonBefore" :record="form" />
            </template>
            <template v-if="form?.addonAfter" #addonAfter>
              <slot name="addonAfter" :record="form" />
            </template>
          </a-input-number>
        </a-form-item>
        <a-divider v-else-if="form.component === 'divider'" />

        <!-- Horizontal Layout -->
        <a-form-item
          v-else-if="form?.layout === 'horizontal' && !form?.hidden"
          :label="form.label"
        >
          <a-row :gutter="16">
            <a-col
              v-for="item in form.items"
              :key="item.key"
              :span="24 / form.items.length"
            >
              <a-form-item
                v-if="item.component === 'input'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <template v-if="item.showExtra" #extra>
                  {{ formState[item.key]?.length ?? 0 }} / {{ item.maxLength }}
                </template>
                <a-input
                  v-model:value="formState[item.key]"
                  :size="item.size"
                  :placeholder="item.placeholder"
                  :maxlength="item.maxLength"
                  :minlength="item.minLength"
                  :suffix="item?.suffix ?? undefined"
                  class="input-form"
                  :disabled="item.disabled"
                />
              </a-form-item>
              <a-form-item
                v-else-if="item.component === 'textarea'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <template v-if="item.showExtra" #extra>
                  {{ formState[item.key]?.length ?? 0 }} / {{ item.maxLength }}
                </template>
                <a-textarea
                  v-model:value="formState[item.key]"
                  :placeholder="item.placeholder"
                  :rows="form?.row ?? 6"
                  :maxlength="item.maxLength"
                  :minlength="item.minLength"
                  :disabled="item.disabled"
                />
              </a-form-item>
              <a-form-item
                v-else-if="item.component === 'select'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <template v-if="item.showExtra" #extra>
                  {{ formState[item.key]?.length ?? 0 }} / {{ item.maxLength }}
                </template>
                <a-select
                  ref="select"
                  v-model:value="formState[item.key]"
                  :options="item.options"
                  :size="item.size"
                  :placeholder="item.placeholder"
                  :show-search="item.showSearch"
                  :disabled="item.disabled"
                  :filter-option="filterOption"
                />
              </a-form-item>

              <a-form-item
                v-else-if="item.component === 'select-level'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <a-select
                  ref="select-tags"
                  v-model:value="formState[item.key]"
                  :options="item.options"
                  :size="item.size"
                  :field-names="{
                    label: 'label',
                    value: 'value',
                    options: 'children'
                  }"
                  :placeholder="item.placeholder"
                  :disabled="item.disabled"
                  :filter-option="filterOption"
                />
              </a-form-item>
              <a-form-item
                v-else-if="item.component === 'select-multiple-tag'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <a-select
                  ref="select"
                  v-model:value="formState[item.key]"
                  :mode="item?.mode ?? 'tags'"
                  :size="item.size"
                  :options="item.options"
                  :field-names="{
                    label: 'label',
                    value: 'value',
                    options: 'children'
                  }"
                  :placeholder="item.placeholder"
                  :show-search="item.showSearch"
                  :disabled="item.disabled"
                  :loading="form?.loading ?? false"
                  :filter-option="filterOption"
                />
              </a-form-item>
              <a-form-item
                v-else-if="item.component === 'switch'"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <a-space>
                  <a-switch v-model:checked="formState[item.key]" />

                  <div>
                    <div class="ant-form-item-label">
                      <label for="switch">
                        {{ item.label }}
                      </label>
                    </div>
                    <div class="text-sm text-gray-8">
                      {{ item.placeholder }}
                    </div>
                  </div>
                </a-space>
              </a-form-item>

              <a-form-item
                v-if="item.component === 'number'"
                :label="item.label"
                :name="item.key"
                :rules="formData.rulesRef[item.key]"
              >
                <a-input-number
                  v-model:value="formState[item.key]"
                  :size="item.size"
                  :placeholder="item.placeholder"
                  :max="item.max"
                  :min="item.min"
                  class="input-form"
                  :formatter="item.formatter"
                  :parser="item.parser"
                  :step="item.step"
                  :decimal-separator="item.decimalSeparator"
                  :disabled="item.disabled"
                  :controls="item?.controls ?? false"
                  :string-mode="item?.type === 'decimal' ?? false"
                >
                  <template v-if="item?.addonBefore" #addonBefore>
                    <slot name="addonBefore" :record="item" />
                  </template>
                  <template v-if="item?.addonAfter" #addonAfter>
                    <slot name="addonAfter" :record="item" />
                  </template>
                </a-input-number>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
      </template>
    </a-form-item>

    <slot name="action-button" />

    <slot name="form-item" />
  </a-form>
</template>

<style lang="scss">
@use './styles.scss';
</style>
