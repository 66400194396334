<script setup lang="ts">
const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  url: 'https://kognisi.id',
  name: 'Kognisi - Media kursus online dan Pengetahuan Digital untuk Generasi Muda',
  description:
    'Kognisi adalah platform pengetahuan digital yang menyajikan konten edukatif, inspiratif, dan relevan bagi generasi muda di Indonesia.',
  inLanguage: 'id',
  publisher: {
    '@type': 'Organization',
    name: 'Kognisi',
    url: 'https://kognisi.id',
    logo: {
      '@type': 'ImageObject',
      url: 'https://kognisi.id/images/logo-kognisi-desktop.webp',
      width: 200,
      height: 50
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'Customer Support',
      email: 'kognisi@growthcenter.id',
      telephone: '+6281331186181',
      areaServed: 'ID',
      availableLanguage: 'id'
    },
    sameAs: [
      'https://www.tiktok.com/@kognisi.id',
      'https://www.instagram.com/kognisikg',
      'https://youtube.com/@KognisiKG'
    ]
  },
  primaryImageOfPage: {
    '@type': 'ImageObject',
    url: 'https://mykg-dev-storage.sgp1.digitaloceanspaces.com/cms/growthpath/banner/banner_1690467676.png',
    width: 1200,
    height: 630
  },
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://kognisi.id'
  },
  about: {
    '@type': 'Thing',
    name: 'Pendidikan, Teknologi, Bisnis, Pengembangan Diri, kursus online'
  },
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: 'https://kognisi.id/search?q={search_term_string}',
      'query-input': 'required name=search_term_string'
    },
    {
      '@type': 'ReadAction',
      target: 'https://kognisi.id'
    }
  ]
}
useHead({
  link: [{ rel: 'canonical', href: 'https://kognisi.id' }],
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify(schemaData)
    }
  ]
})
useSeoMeta({
  robots: 'index, follow'
})
</script>

<template>
  <NuxtLayout name="home">
    <NuxtLoadingIndicator />
    <LazyModulesHomeOrganismsHomeNew />
  </NuxtLayout>
</template>
