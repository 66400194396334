import dayjs from 'dayjs'
import { jsPDF as JsPdf } from 'jspdf'

import fontBold from '~/assets/fonts/roboto-bold.js'
import fontNormal from '~/assets/fonts/roboto-normal.js'
import templateSertifikatKursusCustom from '~/assets/images/course/template-sertifikat-kursus-new.jpg'
import { useCoursesStore } from '~/stores/courses'
import { useUserStore } from '~/stores/user'
import { type CertificateType } from '~~/stores/courses/types'

export const useGenerateCertificate = () => {
  const userStore = useUserStore()
  const coursesStore: Record<string, any> = useCoursesStore()

  const userDetail = computed(() => toRaw(userStore.state?.userDetail?.data))

  const showReview = ref<boolean>(false)
  const activeCourse = ref(null)
  const triggerReview = (course) => {
    activeCourse.value = course
    showReview.value = true
  }

  const onCancelReview = () => {
    activeCourse.value = null
    showReview.value = false
  }

  const visibleAddRating = reactive<Record<string, boolean>>({
    course: false,
    facilitator: false
  })

  const triggerFinishRatingCourse = () => {
    visibleAddRating.course = false
    visibleAddRating.facilitator = true
  }

  const triggerFinishRatingFacilitator = () => {
    visibleAddRating.course = false
    visibleAddRating.facilitator = false
  }

  const submitAddReview = () => {
    coursesStore.fetchCourseDetail({
      payload: activeCourse?.value?.urlSlug,

      resolve: () => {
        showReview.value = false
        visibleAddRating.course = true
      }
    })
  }

  const convertImageToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const dataURL = canvas.toDataURL('image/png')
        resolve(dataURL)
      }
      img.onerror = (err) => {
        reject(err)
      }
      img.src = url
    })
  }

  const calculateAspectRatioFit = (
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ): { width: number; height: number } => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
    return { width: srcWidth * ratio, height: srcHeight * ratio }
  }

  const generateCustomPdf = async (value: CertificateType) => {
    const doc = new JsPdf('landscape', 'mm', 'a4')
    const xOffset = doc.internal.pageSize.getWidth() / 2
    doc.addFileToVFS('roboto-bold.ttf', fontBold)
    doc.addFont('roboto-bold.ttf', 'roboto', 'bold')
    doc.addFileToVFS('roboto-normal.ttf', fontNormal)
    doc.addFont('roboto-normal.ttf', 'roboto', 'normal')
    doc.addImage(templateSertifikatKursusCustom, 'JPEG', 0, 0, 297, 210)

    let logoXposition = 125
    if (value?.isCustomCertificate && value?.courseCertificate?.logoImageUrls) {
      value?.courseCertificate?.logoImageUrls
        ?.split(',')
        ?.forEach((logo, index) => {
          const img = new Image()
          img.crossOrigin = 'Anonymous' // Jika gambar di-host di domain yang berbeda
          img.src = logo
          // eslint-disable-next-line no-loop-func
          img.onload = function () {
            const newSize = calculateAspectRatioFit(
              img.width,
              img.height,
              logoXposition,
              15
            )
            doc.addImage(
              img,
              'JPEG',
              logoXposition,
              15,
              newSize.width,
              newSize.height
            )

            logoXposition += newSize.width + 5
          }
        })
    }
    doc.setFont('roboto', 'bold')
    doc.setFontSize(24)
    doc.setTextColor('#268D7E')
    doc.text(`${value?.userFullName ?? '-'}`, xOffset / 1.9, 78, {
      align: 'left',
      maxWidth: 200
    })
    doc.setFontSize(19)
    doc.setTextColor('#6F777F')
    doc.text(`"${value?.courseName ?? '-'}"`, xOffset / 1.9, 108, {
      align: 'left',
      maxWidth: 200
    })
    doc.setFontSize(12)
    doc.setTextColor('#6F777F')
    doc.text(
      `${dayjs(new Date(value?.createdAt))
        .locale('id')
        .format('DD MMMM YYYY')}`,
      xOffset / 1.9,
      131,
      { align: 'left' }
    )

    const esignSampleBase64 = await convertImageToBase64(
      value?.certificateMetadata?.directorESignImage
    )
    doc.addImage(esignSampleBase64, 'PNG', xOffset / 2, 160, 38, 20)
    doc.text(value?.certificateMetadata?.directorName, xOffset / 1.9, 190, {
      align: 'left',
      maxWidth: 70
    })
    doc.setFont('roboto', 'italic')
    doc.setTextColor('#A7B1BA')
    doc.text(value?.certificateMetadata?.directorPosition, xOffset / 1.9, 196, {
      align: 'left',
      maxWidth: 70
    })

    if (
      value?.isCustomCertificate &&
      value?.courseCertificate?.signatureImageUrl
    ) {
      const signatureBase64: string = await convertImageToBase64(
        value?.courseCertificate?.signatureImageUrl
      )
      doc.addImage(signatureBase64, 'PNG', 160, 160, 38, 20)
      doc.line(160, 183, 205, 183)
      doc.setFont('roboto', 'bold')
      doc.setTextColor('#6F777F')
      doc.text(value?.courseCertificate?.signatureBehalfOf, 161, 190, {
        align: 'left',
        maxWidth: 70
      })
      doc.setFont('roboto', 'italic')
      doc.setTextColor('#A7B1BA')
      doc.text(value?.courseCertificate?.positionBehalfOf, 161, 196, {
        align: 'left',
        maxWidth: 70
      })
    }

    doc.setFont('roboto', 'bold')
    doc.setTextColor('#6F777F')
    doc.text(
      value?.generatedSerial,
      doc.internal.pageSize.getWidth() / 1.06,
      190,
      { align: 'right' }
    )
    doc.save(
      `${value?.courseName}-${dayjs(new Date()).format('YYYYMMDDhhss')}-${
        userDetail.value?.serial
      }.pdf`
    )
  }

  const getCertificate = (course) => {
    coursesStore.generateCourseCertificate({
      payload: {
        courseSerial: course.serial
      },
      resolve: (value) => {
        generateCustomPdf(toRaw(value))
      }
    })
  }

  return {
    getCertificate,
    triggerReview,
    showReview,
    activeCourse,
    onCancelReview,
    triggerFinishRatingCourse,
    triggerFinishRatingFacilitator,
    submitAddReview,
    visibleAddRating
  }
}
