export const useScrollTo = () => {
  const maxLeft = ref<boolean>(false)
  const maxRight = ref<boolean>(true)

  const scrollToHorizontalElem = (element, scrollPixels, duration) => {
    if (!element) {
      return
    }
    const scrollPos = element.scrollLeft
    if (scrollPixels > 0) {
      maxLeft.value = true
      maxRight.value =
        element.clientWidth + scrollPos + scrollPixels < element.scrollWidth
    } else {
      maxRight.value = true
      maxLeft.value = scrollPos - scrollPixels > 0
    }

    // Condition to check if scrolling is required
    if (
      !(
        (scrollPos === 0 || scrollPixels > 0) &&
        (element.clientWidth + scrollPos === element.scrollWidth ||
          scrollPixels < 0)
      )
    ) {
      // Get the start timestamp
      const startTime =
        'now' in window?.performance ? performance?.now() : new Date().getTime()

      // eslint-disable-next-line no-inner-declarations
      function scroll(timestamp) {
        // Calculate the timeelapsed
        const timeElapsed = timestamp - startTime
        // Calculate progress
        const progress = Math.min(timeElapsed / duration, 1)
        // Set the scrolleft
        element.scrollLeft = scrollPos + scrollPixels * progress
        // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
        if (timeElapsed < duration) {
          // Request for animation
          window.requestAnimationFrame(scroll)
        } else {
          return
        }
      }
      // Call requestAnimationFrame on scroll function first time
      window.requestAnimationFrame(scroll)
    }
  }

  const swipeLeft = (elTargetRel, scrollPixels = -100, duration = 800) => {
    if (!elTargetRel) {
      return
    }
    const element = document.getElementById(elTargetRel)
    scrollToHorizontalElem(element, scrollPixels, duration)
  }
  const swipeRight = (elTargetRel, scrollPixels = 100, duration = 800) => {
    if (!elTargetRel) {
      return
    }
    const element = document.getElementById(elTargetRel)
    scrollToHorizontalElem(element, scrollPixels, duration)
  }

  return {
    maxLeft,
    maxRight,
    swipeLeft,
    swipeRight
  }
}
