<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isActive?: boolean
    showSidebar?: boolean
  }>(),
  {
    isActive: false,
    showSidebar: false
  }
)

const { isActive, showSidebar } = toRefs(props)
const classes = computed(() => {
  return {
    active: isActive.value,
    'active-sidebar': showSidebar.value
  }
})
</script>

<template>
  <div class="kg-overlay" :class="classes" />
</template>

<style lang="scss">
@use './styles.scss';
</style>
