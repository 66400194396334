<!-- eslint-disable no-undefined -->
<script setup lang="ts">
interface PropTypes {
  title?: string
  body?: string
  type?: string
  timeout?: any
  created?: any
  id?: any
  expire?: any
}
interface ParamTypes {
  params?: PropTypes | null | undefined
}
const props = withDefaults(defineProps<ParamTypes>(), {
  params: undefined
})

const { params } = toRefs(props)

watch(params, (newVal: PropTypes) => {
  add(newVal)
})

const defaults = ref<Record<string, any>>({
  title: undefined,
  body: undefined,
  timeout: 2
})
const content = ref([])

const add = (params: PropTypes) => {
  for (const key in defaults.value) {
    // eslint-disable-next-line no-undefined
    if (params[key] === undefined) {
      params[key] = defaults.value[key]
    }
  }

  params.created = Date.now()
  params.id = Math.random()
  // eslint-disable-next-line max-statements-per-line
  params.expire = setTimeout(() => {
    remove(params.id)
  }, params.timeout * 1000)

  content.value.unshift(params)
}

const remove = (id?: number) => {
  const start: number = Number(index(id))
  content.value.splice(start, 1)
}

const index = (id?: number | string) => {
  for (const key in content.value) {
    if (id === content.value[key].id) {
      return key
    }
  }
}
</script>

<template>
  <div id="toasts">
    <transition-group name="toast-list" tag="ul">
      <a-alert
        v-for="toast of content"
        :key="toast.id"
        class="toast-list-item"
        :message="toast.title"
        :description="toast.body"
        :type="toast.type"
        show-icon
      />
    </transition-group>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
