<script setup lang="ts">
interface PropTypes {
  height?: string
  title?: string
  description?: string
}
const props = withDefaults(defineProps<PropTypes>(), {
  height: '150px',
  title: null,
  description: 'Data tidak tersedia'
})
const { height, description } = toRefs(props)
</script>

<template>
  <div class="kg-empty">
    <div class="kg-empty__image" :style="`height: ${height}`">
      <img
        src="~/assets/images/empty-result.svg"
        alt="empty-image"
        loading="lazy"
      />
    </div>
    <h3 v-if="title" class="mb-2">
      {{ title }}
    </h3>
    <p class="text-gray-400 text-center">
      {{ description }}
    </p>
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
