<template>
  <OrganismsFullformWrapper>
    <div class="kg-fullform-middle-align">
      <slot />
    </div>
  </OrganismsFullformWrapper>
</template>

<style lang="scss" scoped>
@use './styles.scss';
</style>
