<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import { useAuthStore } from '~~/stores/auth'
import type { DataRequestPasswordResetType } from '~~/stores/auth/type'

interface IProps {
  visible: boolean
}

const forgotPwModalProps = defineProps<IProps>()
const { visible } = toRefs(forgotPwModalProps)

const { validateString, validateEmail } = useValidationForm()

const formRef = ref<FormInstance>()
const formState = reactive<DataRequestPasswordResetType>({ email: '' })

const rules: Record<string, Rule[]> = {
  fullname: [{ required: true, validator: validateString, trigger: 'change' }]
}

const showForgotPasswordSentModal = ref(false)
const openForgotPasswordSentModal = () => {
  showForgotPasswordSentModal.value = true
}
const closeForgotPasswordSentModal = () => {
  showForgotPasswordSentModal.value = false
}

const emit = defineEmits(['cancel'])
const auth = useAuthStore()
const onSubmit = (payload: DataRequestPasswordResetType) => {
  auth.requestPasswordReset({
    payload,
    resolve: () => {
      openForgotPasswordSentModal()
      emit('cancel')
    }
  })
}
</script>

<template>
  <a-modal
    class="kg"
    :visible="visible"
    :footer="null"
    :width="450"
    @cancel="$emit('cancel')"
  >
    <div class="kg-forgot-password-form">
      <div class="form-content__header">
        <div class="header-title">Lupa kata sandi?</div>
        <div class="header-subtitle">
          Masukkan e-mail yang terdaftar. Kami akan mengirimkan link untuk atur
          ulang kata sandi.
        </div>
      </div>

      <a-form
        ref="formRef"
        name="signup"
        layout="vertical"
        :rules="rules"
        :model="formState"
        autocomplete="off"
        class="form-content__body"
        @finish="onSubmit"
      >
        <a-form-item label="Email" name="email">
          <a-input v-model:value="formState.email" size="large" />
        </a-form-item>

        <a-button
          html-type="submit"
          type="primary"
          size="large"
          :loading="auth.loading"
          block
        >
          Selanjutnya
        </a-button>
      </a-form>
    </div>
    <MoleculesForgotPasswordSentModal
      :visible="showForgotPasswordSentModal"
      type="email"
      :recipient="formState.email"
      @cancel="closeForgotPasswordSentModal"
    />
  </a-modal>
</template>

<style lang="scss">
@use './styles.scss';
</style>
