import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/account.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/default.vue").then(m => m.default || m)),
  fullform: defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/fullform.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/home.vue").then(m => m.default || m)),
  "kursus-certificate": defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/kursusCertificate.vue").then(m => m.default || m)),
  "kursus-detail": defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/kursusDetail.vue").then(m => m.default || m)),
  "kursus-materi": defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/kursusMateri.vue").then(m => m.default || m)),
  "static-page": defineAsyncComponent(() => import("/usr/src/nuxt-app/layouts/staticPage.vue").then(m => m.default || m))
}