<script setup lang="ts">
import type { SearchResult } from '~~/types/topBar'

withDefaults(
  defineProps<{
    search?: string
    resultSearch?: SearchResult
    isLoading?: boolean
  }>(),
  {
    search: '',
    resultSearch: () => null,
    isLoading: false
  }
)

const emits = defineEmits(['click'])
</script>

<template>
  <ul id="searchbar-dropdown" class="searchbar-dropdown">
    <li v-if="isLoading" class="px-3 py-2">
      <a-skeleton :title="false" :paragraph="{ rows: 5 }" />
    </li>

    <li
      v-else-if="
        !resultSearch?.keywords.length && !resultSearch?.courses.length
      "
      class="px-3 py-2"
    >
      Maaf, tidak ada hasil untuk “{{ search }}”
    </li>

    <template v-else>
      <template v-if="resultSearch?.keywords.length">
        <li
          v-for="(keyword, index) in resultSearch?.keywords"
          :key="index"
          class="searchbar-dropdown__item"
          @click="emits('click')"
        >
          <NuxtLink :to="`/search?q=${keyword?.label}`">
            <SearchOutlined />
            <span class="item-text">
              <div v-html="keyword?.search ?? keyword?.label"></div>
            </span>
          </NuxtLink>
        </li>
      </template>
      <template v-if="resultSearch?.courses.length">
        <li
          v-for="(course, index) in resultSearch?.courses"
          :key="index"
          class="searchbar-dropdown__item"
          @click="emits('click')"
        >
          <NuxtLink :to="`/kursus/${course.path}`">
            <FileOutlined />
            <span class="item-text">
              <div v-html="course?.search ?? course?.label"></div>
            </span>
          </NuxtLink>
        </li>
      </template>
      <!-- <li class="searchbar-dropdown__item more">
        <NuxtLink to="/search"> Lihat course lainnya (15) </NuxtLink>
      </li> -->
    </template>
  </ul>
</template>

<style lang="scss">
@use './styles.scss';
</style>
