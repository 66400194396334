<script setup lang="ts">
import { stringifyQuery } from 'ufo'
import { useUserStore } from '~/stores/user'

const props = withDefaults(
  defineProps<{
    id?: string
    title?: string
    url?: string
    isSeeMore?: boolean
    typeRef?: string
  }>(),
  {
    // eslint-disable-next-line no-undefined
    id: undefined,
    title: 'Default title',
    url: '#',
    isSeeMore: false,
    typeRef: 'kursusSection'
  }
)
const { title, url, isSeeMore, typeRef } = toRefs(props)

const userStore = useUserStore()
const studiSaya = computed(() => userStore.state?.studiSaya)

const query = ref<Record<string, any>>({
  search: null,
  filter: 'ALL'
})
userStore.fetchStudiSaya({
  payload: { params: query.value }
})
</script>

<template>
  <section v-if="studiSaya?.data" :id="id" class="section-course">
    <div class="container">
      <div class="section-course__continue">
        <ModulesHomeAtomsCourseHeader
          :title="title"
          url="/user/studi-saya"
          :is-see-more="isSeeMore"
        />

        <ul :id="typeRef" class="section-course__list">
          <template v-for="course in studiSaya?.data" :key="course.serial">
            <li
              v-if="course?.status !== 'FINISHED'"
              class="section-course__item"
            >
              <ModulesHomeMoleculesCardCourseContinue :course="course" />
            </li>
          </template>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
