<script setup lang="ts">
const { isMobileOrTablet } = useDevice()

defineOptions({
  name: 'SectionHero'
})
</script>

<template>
  <section id="hero">
    <div class="container">
      <!--  -->
      <a-row :gutter="[24, 24]">
        <a-col :xs="15" :md="15" :lg="15">
          <a-row :gutter="[24, 24]">
            <a-col :span="24">
              <h1>Upskilling Sesuai Potensi Diri</h1>
            </a-col>
            <a-col :span="24">
              <p>
                Pelajari Hard Skills dan Soft Skills Sekarang untuk Raih Karier
                Impianmu
              </p>
            </a-col>
            <a-col :span="24">
              <a-button
                type="primary"
                :size="isMobileOrTablet ? null : 'large'"
                @click="$router.push('/signin')"
              >
                Daftar Sekarang
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
