<script setup lang="ts">
import { useAuthStore } from './stores/auth'
import { useMessageStore } from './stores/message'

const messageStore = useMessageStore()
const authStore = useAuthStore()

const messageParams = computed(() => messageStore?.params)

const title =
  'Kognisi - Media kursus online dan Pengetahuan Digital untuk Generasi Muda'
const description =
  'Kognisi adalah platform pengetahuan digital yang menyajikan konten edukatif, inspiratif, dan relevan bagi generasi muda di Indonesia.'
const imageBanner =
  'https://mykg-dev-storage.sgp1.digitaloceanspaces.com/cms/growthpath/banner/banner_1690467676.png'
const url = 'https://kognisi.id'

useHead({
  titleTemplate: authStore.getTitle(),
  htmlAttrs: {
    lang: 'id'
  },
  link: [
    { rel: 'shortcut icon', href: '/favicon.ico' },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-96x96.png',
      sizes: '96x96'
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: '/favicon.svg'
    },
    { rel: 'icon', href: '/web-app-manifest-192x192.png', sizes: '192x192' },
    { rel: 'icon', href: '/web-app-manifest-512x512.png', sizes: '512x512' },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
      sizes: '180x180'
    },
    { rel: 'manifest', href: '/site.webmanifest' }
  ]
})

useSeoMeta({
  title,
  description,
  author: 'kognisi.id',
  keywords:
    'kursus, kursus online, pelatihan online, pengembangan keterampilan, sertifikat online, soft skills, hard skills, pendidikan jarak jauh, belajar mandiri, Kognisi, pengembangan diri, kursus kepemimpinan, kursus komunikasi, kursus teknologi, pelatihan profesional, e-learning, komunitas belajar, webinar, pendidikan berkualitas, sertifikat, online',
  themeColor: '#ffffff',
  appleMobileWebAppCapable: 'yes',
  appleMobileWebAppTitle: 'kognisi.id',
  mobileWebAppCapable: 'yes',
  ogTitle: title,
  ogDescription: description,
  ogImage: imageBanner,
  ogImageAlt: 'Kognisi.id',
  ogImageUrl: imageBanner,
  ogSiteName: 'Kognisi',
  ogLocale: 'en_US',
  ogUrl: url,
  ogType: 'website',
  twitterCard: 'summary_large_image',
  twitterTitle: title,
  twitterDescription: description,
  twitterImage: imageBanner,
  twitterSite: '@kognisikg',
  twitterCreator: '@growthcenter_id'
})
</script>
<template>
  <div>
    <Head>
      <Link rel="preconnect" href="https://fonts.googleapis.com" />
      <Link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
      <Link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <Link
        href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Head>
    <div class="kg">
      <AtomsToasts :params="messageParams" />
      <NuxtPage />
    </div>
  </div>
</template>
