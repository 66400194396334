<script setup lang="ts">
withDefaults(
  defineProps<{
    showReview?: boolean
  }>(),
  {
    showReview: false
  }
)

const emits = defineEmits(['cancel', 'submit'])
</script>

<template>
  <AtomsModal
    :visible="showReview"
    :width="756"
    centered
    closable
    @cancel="emits('cancel')"
  >
    <div class="course-review">
      <img src="~/assets/images/account/review.png" loading="lazy" />
      <div class="course-review__title">Beri Ulasan Dulu Yuk!</div>
      <div class="course-review__desciprtion">
        Kamu baru dapat mengunduh sertifikat setelah memberikan ulasan mengenai
        proses belajar Kamu di kelas ini.
      </div>
      <a-button type="primary" size="large" @click="emits('submit')">
        Tulis Ulasan
      </a-button>
    </div>
  </AtomsModal>
</template>

<style lang="scss">
@use './styles.scss';
</style>
