<script setup lang="ts">
import { useCoursesStore } from '~~/stores/courses'
import type { SearchResult } from '#build/types/topBar'

const courseStore = useCoursesStore()
const route = useRoute()
const router = useRouter()

const showList = ref(false)
const searchbarRef = ref(null)
const isLoading = ref(false)
const search = ref(route?.query?.q ?? '')
const resultSearch = ref<SearchResult>()

useClickOutside(searchbarRef, () => {
  showList.value = false
})

const triggerSearch = useDebounce((e) => {
  isLoading.value = true
  courseStore.searchCourse({
    payload: {
      prefixTitle: search.value
    },
    resolve: (data) => {
      const value = e.target.value
      showList.value = true
      resultSearch.value = {
        keywords: data?.keywords?.map((keyword) => ({
          label: keyword,
          search: keyword?.replace(new RegExp(value, 'gi'), '<b>$&</b>')
        })),
        courses: data?.courses?.map((item) => ({
          label: item.title,
          path: item.urlSlug,
          search: item.title?.replace(new RegExp(value, 'gi'), '<b>$&</b>')
        }))
      }
      isLoading.value = false
    },
    reject: () => {
      isLoading.value = false
    }
  })
}, 500)

const onPressEnter = () => {
  if (search.value >= 3) {
    router.push({
      path: 'search',
      query: {
        q: search.value
      }
    })
    showList.value = false
  }
}

watch(
  () => route?.query?.q,
  (newVal) => {
    search.value = newVal
  },
  { deep: true }
)
</script>

<template>
  <div id="searchbar" ref="searchbarRef" class="searchbar">
    <a-input
      v-model:value="search"
      class="searchbar-input"
      placeholder="Mau belajar apa hari ini?"
      size="large"
      allow-clear
      @change="triggerSearch"
      @press-enter="onPressEnter"
    >
      <template #suffix>
        <search-outlined />
      </template>
    </a-input>
    <AtomsSearchBarDropdown
      v-if="showList"
      :search="search"
      :result-search="resultSearch"
      :is-loading="isLoading"
      @click="showList = false"
    />
  </div>
</template>

<style lang="scss">
@use './styles.scss';
</style>
