import routerOptions0 from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/usr/src/nuxt-app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}