<script setup lang="ts">
interface PropTypes {
  id?: string
  title?: string
  url?: string
  data?: any
  isLoading?: boolean
}
const props = withDefaults(defineProps<PropTypes>(), {
  // eslint-disable-next-line no-undefined
  id: undefined,
  title: 'Default title',
  url: '#',
  data: [],
  isLoading: false
})
const { id, title, url, data, isLoading } = toRefs(props)
</script>

<template>
  <section id="section-mentor" class="section-mentor">
    <div class="container">
      <h2 class="section-mentor__title">
        Dibimbing Sama Praktisi Berpengalaman<br />
        Bantu Dapatkan Karier Impianmu
      </h2>
      <ul :id="id" class="section-mentor__list">
        <template v-if="isLoading">
          <li v-for="i in 5" :key="i" class="section-mentor__item">
            <ModulesHomeMoleculesCardProfile :is-loading="true" />
          </li>
        </template>

        <template v-else>
          <li
            v-for="(item, index) in data"
            :key="index"
            class="section-mentor__item"
          >
            <ModulesHomeMoleculesCardProfile
              :data="item"
              :is-loading="isLoading"
            />
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
