<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: string
    visible?: boolean
  }>(),
  {
    type: null,
    visible: false
  }
)
const emits = defineEmits(['cancel'])
const { type } = toRefs(props)

const onSigninFormModalSuccess = () => {
  window.location.reload()
}
</script>

<template>
  <AtomsModal
    id="modal-signin"
    class="modal-signin"
    :visible="visible"
    :class="type === 'sidebar' ? 'modal-signin-sidebar' : null"
    :centered="type !== 'sidebar'"
    :width="445"
    @cancel="emits('cancel')"
  >
    <MoleculesSigninForm @success="onSigninFormModalSuccess" />
  </AtomsModal>
</template>

<style lang="scss">
@use './styles.scss';
</style>
